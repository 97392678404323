import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.layouts;

export const isSidebarOpenSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['isSidebarOpen'] => layouts.isSidebarOpen
);

export const toastsSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['toasts'] => layouts.toasts
);

export const paginationSelectorFactory = (type: Type.PaginationType) =>
  createSelector(rootSelector, (layouts: Layouts.Root): Layouts.Pagination => layouts.pagination[type]);

export const selectedWorkflowTenantSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['selectedWorkflowTenant'] => layouts.selectedWorkflowTenant
);
