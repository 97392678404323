import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { historyCountSelector, historyItemsSelector } from 'modules/uptime/selectors';
import { fetchHistoryAction } from 'modules/uptime';
import { dateFormat } from '@utiligize/shared/utils';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';
import { paginationSelectorFactory } from 'modules/layouts/selectors';

const TableHistory: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_HISTORY;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const items = useSelector(historyItemsSelector);
  const count = useSelector(historyCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchHistoryAction(params)).then(
        (action: Shared.ReduxAction<{ historyItems: Uptime.Root['historyItems'] }>) =>
          action.payload?.historyItems || []
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      waitForDependencies={!filters?.test}
      isDownloadCSVEnabled
    >
      {(items || []).map(item => (
        <tr key={item.test_run_timestamp}>
          <td className="text-nowrap">{item.test_run_timestamp ? dateFormat(item.test_run_timestamp, 'L LT') : '-'}</td>
          <td>{item.duration || '-'}</td>
          <td>{item.tags.join(', ')}</td>
          <td>{item.success ? 'Yes' : 'No'}</td>
          <td>{item.error_message || '-'}</td>
          <td>{item.dashboard_version || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableHistory;
