import { AxiosError, AxiosRequestConfig } from 'axios';
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setErrorToastAction } from 'modules/layouts';
import { setStorageItem } from 'utils';
import { AssetLifeAPI, StorageKeys, isDevelopment } from 'constants/index';
import { UserService } from '@utiligize/shared/services';

interface Props {
  children: React.ReactNode[];
}

const SetupAxios: React.FC<Props> = ({ children }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleResponseError = useCallback(
    (error: AxiosError<{ errorCode: number; error: any; errorMessage: string }>) => {
      // Handle request network errors
      if (error.code === 'ERR_NETWORK') {
        dispatch(setErrorToastAction(`${error.message} (${error.config?.url})`));
      }

      if (error.response?.request) {
        const message =
          error.response?.data?.error?.errorMessage ||
          error.response?.data?.error ||
          error.response?.data?.errorMessage ||
          error.message;

        dispatch(setErrorToastAction(message));

        if (isDevelopment) {
          console.error('Interceptor: ', message);
        }
      }
      return Promise.reject(error);
    },
    [dispatch]
  );

  useEffect(() => {
    const assetLifeId = (() => {
      AssetLifeAPI.interceptors.request.use((req: AxiosRequestConfig) => {
        if (UserService.isLoggedIn()) {
          return UserService.updateToken((refreshed: boolean) => {
            if (refreshed) processToken();
            return Promise.resolve(req);
          });
        }
      });
      return AssetLifeAPI.interceptors.response.use(response => response, handleResponseError);
    })();

    return () => {
      AssetLifeAPI.interceptors.request.eject(assetLifeId);
      AssetLifeAPI.interceptors.response.eject(assetLifeId);
    };
  }, [handleResponseError]);

  useEffect(() => {
    if (!UserService.isLoggedIn()) return;
    if (UserService.getToken()) return processToken();

    delete AssetLifeAPI.defaults.headers.common.Authorization;
    delete AssetLifeAPI.defaults.headers.common.tenant;
  }, []);

  return <>{children}</>;
};

export const processToken = () => {
  AssetLifeAPI.defaults.headers.common.Authorization = `Bearer ${UserService.getToken()}`;
  AssetLifeAPI.defaults.headers.common.tenant = 'Tenant Utiligize ApS';

  const parsedAccessToken: any = UserService.getParsedToken();
  setStorageItem({
    [StorageKeys.USER_CLAIMS]: {
      firstName: parsedAccessToken.given_name,
      lastName: parsedAccessToken.family_name,
      email: parsedAccessToken.email,
      tenants: parsedAccessToken.tenants,
    },
  });
};

export default SetupAxios;
