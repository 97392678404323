import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.calculations;

export const ferretsSelector = createSelector(
  rootSelector,
  (calculations: Calculations.Root): Calculations.Root['ferrets'] => calculations.ferrets
);

export const loadflowItemsSelector = createSelector(
  rootSelector,
  (calculations: Calculations.Root): Calculations.Root['loadflowItems'] => calculations.loadflowItems
);
