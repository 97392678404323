import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { isSidebarOpenSelector } from 'modules/layouts/selectors';
import PopoverUserSettings from './PopoverUserSettings';

const Navigation: React.FC = () => {
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  return (
    <StyledContainer className="main-header navbar" $isSidebarOpen={isSidebarOpen}>
      <StyledList />
      <div className="ml-auto d-flex align-items-center flex-shrink-0">
        <PopoverUserSettings />
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.nav<{ $isSidebarOpen: boolean }>`
  &&&&& {
    padding: 10px;
    background: ${props => props.theme.colors.white};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    flex-wrap: nowrap;
    ${({ $isSidebarOpen }) => !$isSidebarOpen && `margin-left: 60px!important`};
  }
`;

const StyledList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-right: 8px;
  flex-shrink: 0;
`;

export default Navigation;
