import styled from 'styled-components';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { statusOverviewsSelector } from 'modules/uptime/selectors';
import { fetchStatusOverviewsAction } from 'modules/uptime';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

interface Props {
  shouldFetch: boolean;
  resetCounter: () => void;
}

const TableStatusOverview: React.FC<Props> = ({ shouldFetch, resetCounter }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_STATUS_OVERVIEW;
  const items = useSelector(statusOverviewsSelector);

  const sendRequest = useCallback(
    () => dispatch(fetchStatusOverviewsAction()).finally(resetCounter),
    [dispatch, resetCounter]
  );

  useEffect(() => {
    if (shouldFetch) sendRequest();
  }, [shouldFetch, resetCounter, sendRequest]);

  return (
    <DataTable
      paginationType={type}
      totalAmount={items?.length}
      sendRequest={sendRequest}
      containerClassName="border-top-0"
    >
      {items.map(item => (
        <StyledTR key={item.tenant}>
          <td>{item.tenant || '-'}</td>
          <td>{item.gis_latest_data_timestamp || '-'}</td>
          <td
            className={classNames({
              'bg-success': item.last_etl_run_successful,
              'bg-danger': !item.last_etl_run_successful,
            })}
          >
            {item.etl_latest_time_finished || '-'}
          </td>
          <td
            className={classNames({
              'bg-success': item.last_network_loading_run_successful,
              'bg-danger': !item.last_network_loading_run_successful,
            })}
          >
            {item.network_loading_latest_time_finished || '-'}
          </td>
          <td
            className={classNames({
              'bg-success': item.last_map_update_successful,
              'bg-danger': !item.last_map_update_successful,
            })}
          >
            {item.map_latest_update || '-'}
          </td>
          <td
            className={classNames('text-center', {
              'bg-success': item.last_network_loading_run_recent_enough,
              'bg-danger': !item.last_network_loading_run_recent_enough,
            })}
          >
            {item.last_network_loading_run_recent_enough ? 'Yes' : 'No'}
          </td>
          <td
            className={classNames('text-center', {
              'bg-success': item.success_rate === 100,
              'bg-warning': item.success_rate >= 80 && item.success_rate < 100,
              'bg-danger': item.success_rate < 80,
            })}
          >
            {typeof item.success_rate === 'number' ? `${item.success_rate}%` : '-'}
          </td>
        </StyledTR>
      ))}
    </DataTable>
  );
};

const StyledTR = styled.tr`
  td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
`;

export default TableStatusOverview;
