import { handleActions, combineActions } from 'redux-actions';
import {
  resetReduxStateAction,
  fetchStatusOverviewsAction,
  fetchTestOverviewsAction,
  fetchFunctionalityTestsAction,
  fetchHistoryAction,
} from './actions';

const initialState: Uptime.Root = {
  statusOverviews: [],
  testOverviews: [],
  testOverviewsLastTestRunTimestamp: null,
  testsCount: 0,
  testsHash: {},
  historyCount: 0,
  historyItems: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    resetReduxStateAction,
    fetchStatusOverviewsAction,
    fetchTestOverviewsAction,
    fetchFunctionalityTestsAction,
    fetchHistoryAction
  ) as any]: {
    next: (
      state: Uptime.Root,
      action: Shared.ReduxAction<Partial<Uptime.Root> & { skipStoreUpdate: true | undefined }>
    ): Uptime.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export { fetchStatusOverviewsAction, fetchTestOverviewsAction, fetchFunctionalityTestsAction, fetchHistoryAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
