import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLoadflowFlowTypeOptionsAction } from 'modules/options';
import { loadflowFlowTypeOptionsSelectorFactory } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from '@utiligize/shared/components';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['flow'] | null;
  environment: Layouts.Filters['environment'] | null;
}

const SelectFlowType: React.FC<Props> = ({ value, environment, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const optionsSelector = useMemo(() => loadflowFlowTypeOptionsSelectorFactory(environment!), [environment]);
  const options = useSelector(optionsSelector);

  useEffect(() => {
    if (!environment || options) return;
    setIsLoading(true);
    dispatch(fetchLoadflowFlowTypeOptionsAction(environment)).finally(() => setIsLoading(false));
  }, [environment, options, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        if (value === option.value) acc.selectValues.push(option);
        acc.selectOptions.push(option);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
  }, [value, options]);

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={isLoading}
      value={selectValues}
      options={selectOptions}
      {...props}
      variant="small"
      isSearchable={false}
      labelKey="Flow type"
    />
  );
};

export default SelectFlowType;
