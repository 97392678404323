import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTagOptionsAction } from 'modules/options';
import { tagsOptionsSelector } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from '@utiligize/shared/components';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['tags'] | null;
}

const SelectTags: React.FC<Props> = ({ value, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const options = useSelector(tagsOptionsSelector);

  useEffect(() => {
    if (options) return;
    setIsLoading(true);
    dispatch(fetchTagOptionsAction()).finally(() => setIsLoading(false));
  }, [options, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        if (value?.includes(String(option.value))) acc.selectValues.push(option);
        acc.selectOptions.push(option);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
  }, [value, options]);

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey=""
      mutedTextLabelKey="Tags"
      value={selectValues}
      options={selectOptions}
      {...props}
      variant="small"
      isSearchable={false}
      isClearable
      isMulti
      blurInputOnSelect
    />
  );
};

export default SelectTags;
