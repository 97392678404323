import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useAutoFetch } from 'hooks';
import { DataTableTemplate } from '@utiligize/shared/components';
import TableStatusOverview from './TableStatusOverview';

const ViewStatusOverview: React.FC = () => {
  const { nextUpdateDiff, shouldFetch, resetCounter } = useAutoFetch({ fetchInterval: 5 * 60 * 1000 }); // 5 mins

  return (
    <>
      <DataTableTemplate>
        <Row>
          <Col xs="auto" className="d-flex ml-auto">
            <small className="text-muted">
              Next update in{' '}
              <span style={{ width: '22px', display: 'inline-block', textAlign: 'center' }} className="text-bold">
                {nextUpdateDiff}
              </span>{' '}
              seconds
            </small>
          </Col>
        </Row>
        <TableStatusOverview shouldFetch={shouldFetch} resetCounter={resetCounter} />
      </DataTableTemplate>
    </>
  );
};

export default ViewStatusOverview;
