import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLoadflowStepsOptionsAction } from 'modules/options';
import { loadflowStepsOptionsSelectorFactory } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from '@utiligize/shared/components';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Calculations.NewExecution['steps'];
  flow: Calculations.NewExecution['flow'];
  environment: Layouts.Filters['environment'] | null;
}

const SelectSteps: React.FC<Props> = ({ name, value, flow, environment, onChange, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const optionsSelector = useMemo(
    () => loadflowStepsOptionsSelectorFactory(`${flow}${environment}`),
    [flow, environment]
  );
  const options = useSelector(optionsSelector);

  useEffect(() => {
    if (!flow || !environment || options) return;
    setIsLoading(true);
    dispatch(fetchLoadflowStepsOptionsAction(flow, environment)).finally(() => setIsLoading(false));
  }, [flow, environment, options, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        if (value?.includes(option.value as string)) acc.selectValues.push(option);
        acc.selectOptions.push(option);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
  }, [value, options]);

  useEffect(() => {
    if (!options) return;
    onChange?.(options, { name });
  }, [onChange, name, options]);

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={isLoading}
      name={name}
      value={selectValues}
      options={selectOptions}
      onChange={onChange}
      {...props}
      variant="small"
      isSearchable={false}
      labelKey="Steps"
      isClearable
      isMulti
    />
  );
};

export default SelectSteps;
