import styled from 'styled-components';
import React from 'react';
import { NavLink, NavLinkProps, Link, LinkProps } from 'react-router-dom';
import { OverlayTriggerTooltip } from '@utiligize/shared/components';
import { Spinner } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  'data-id'?: number | string;
  icon?: React.ReactElement;
  tooltipKey?: string;
  tooltipPlacement?: Placement;
  labelKey?: string;
  dataMarker?: string;
  linkProps?: LinkProps | NavLinkProps;
  marginLeft?: boolean;
  marginRight?: boolean;
  loading?: boolean;
  size?: 'default' | 'small' | 'large'; // default - 32px, small - 26px, large - 38px
  variant?: 'default' | 'primary' | 'primary-outline' | 'primary-link' | 'danger' | 'danger-outline' | 'light';
  getIntl?: (localeKey: string, options?: {}) => string;
}

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    type = 'button',
    icon,
    tooltipKey = '',
    tooltipPlacement = 'top',
    labelKey = '',
    dataMarker = '',
    linkProps,
    marginLeft = false,
    marginRight = false,
    loading = false,
    variant,
    getIntl = str => str,
    ...rest
  },
  ref
) => {
  const Button = (
    <StyledButton
      type={type}
      $isIconExist={Boolean(icon)}
      $isLabelExist={Boolean(labelKey)}
      $marginLeft={!linkProps && marginLeft}
      $marginRight={!linkProps && marginRight}
      $variant={variant}
      data-marker={dataMarker}
      {...rest}
      ref={ref}
    >
      <>
        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : icon}{' '}
        {labelKey && <span className="label">{getIntl(labelKey)}</span>}
      </>
    </StyledButton>
  );

  const WrappedButton = linkProps ? (
    <StyledLink
      {...linkProps}
      as={linkProps.hasOwnProperty('exact') ? NavLink : Link}
      data-margin-left={marginLeft}
      data-margin-right={marginRight}
      size={rest.size}
    >
      {Button}
    </StyledLink>
  ) : (
    Button
  );

  return tooltipKey ? (
    <OverlayTriggerTooltip
      placement={tooltipPlacement}
      trigger={['hover', 'hover']}
      overlayId={`${rest['data-id'] || ''}-${tooltipKey}`}
      overlayChildren={getIntl(tooltipKey)}
    >
      {WrappedButton}
    </OverlayTriggerTooltip>
  ) : (
    WrappedButton
  );
};

const StyledButton = styled.button<{
  $isIconExist?: boolean;
  $isLabelExist: boolean;
  $marginLeft: boolean;
  $marginRight: boolean;
  size?: ButtonProps['size'];
  $variant?: ButtonProps['variant'];
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition:
    color 0.2s ease-in,
    border-color 0.2s ease-in,
    background 0.2s ease-in;
  font-weight: 500;
  vertical-align: top;
  padding: 0;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) > svg {
    fill: currentColor;
  }

  > span.label {
    white-space: nowrap;
    padding-top: 1px;
  }

  ${props => {
    switch (props.size) {
      case 'small':
        return `
          font-size: 13px;
          min-width: 26px;
          height: 26px;
          ${props.$isLabelExist ? 'padding: 0 8px;' : ''};
          ${props.$marginLeft ? 'margin-left: 8px;' : ''};
          ${props.$marginRight ? 'margin-right: 8px;' : ''};
          padding: 4.5px;
    
          > svg {
            width: 15px;
            height: 15px;
          }
    
          > span.label {
            margin-left: ${props.$isIconExist ? 4 : 0}px;
          }
        `;
      case 'large':
        return `
          font-size: 15px;
          min-width: 38px;
          height: 38px;
          ${props.$isLabelExist ? 'padding: 0 12px;' : ''};
          ${props.$marginLeft ? 'margin-left: 10px;' : ''};
          ${props.$marginRight ? 'margin-right: 10px;' : ''};
    
          > svg {
            width: 21px;
            height: 21px;
          }
    
          > span.label {
            margin-left: ${props.$isIconExist ? 8 : 0}px;
          }
        `;
      default:
        return `
          font-size: 14px;
          min-width: 32px;
          height: 32px;
          ${props.$isLabelExist ? 'padding: 0 10px;' : ''};
          ${props.$marginLeft ? 'margin-left: 10px;' : ''};
          ${props.$marginRight ? 'margin-right: 10px;' : ''};

          > svg {
            width: 18px;
            height: 18px;
          }
        
          > span.label {
            margin-left: ${props.$isIconExist ? 6 : 0}px;
          }
        `;
    }
  }}

  ${props => {
    switch (props.$variant) {
      case 'primary':
        return `
          background: ${props.theme.colors.purple800};
          border: 1px solid ${props.theme.colors.purple800};
          color: ${props.theme.colors.white};

          &:hover:not(:disabled) {
            background: ${props.theme.colors.purple50};
            border-color: ${props.theme.colors.purple800};
            color: ${props.theme.colors.purple800};
          }

          > svg {
            fill: ${props.theme.colors.white};
          }
        `;
      case 'primary-outline':
        return `
          background: ${props.theme.colors.white};
          border: 1px solid ${props.theme.colors.purple800};
          color: ${props.theme.colors.purple800};

          &:hover:not(:disabled) {
            color: ${props.theme.colors.purple800};
            background: ${props.theme.colors.purple50};
            border-color: ${props.theme.colors.purple800};
          }

          > svg {
            fill: ${props.theme.colors.purple800};
          }
        `;
      case 'primary-link':
        return `
          background: unset;
          height: auto;
          border: none;
          padding: 0;
          color: ${props.theme.colors.purple800};

          &:hover:not(:disabled) {
            color: ${props.theme.colors.purple900};
          }

          > svg {
            fill: ${props.theme.colors.purple800};
          }

          > span.label {
            padding-top: 0;
            font-size: inherit;
            line-height: inherit;
          }
        `;
      case 'danger':
        return `
          background: ${props.theme.colors.red500};
          border: 1px solid ${props.theme.colors.red500};
          color: ${props.theme.colors.white};

          &:hover:not(:disabled) {
            background: ${props.theme.colors.red600};
            border-color: ${props.theme.colors.red600};
          }

          > svg {
            fill: ${props.theme.colors.white};
          }
        `;
      case 'danger-outline':
        return `
          background: ${props.theme.colors.white};
          border: 1px solid ${props.theme.colors.red500};
          color: ${props.theme.colors.red500};

          &:hover:not(:disabled) {
            color: ${props.theme.colors.red600};
            background: ${props.theme.colors.red50};
            border-color: ${props.theme.colors.red600};
          }

          > svg {
            fill: ${props.theme.colors.red500};
          }
        `;
      case 'light':
        return `
            background: ${props.theme.colors.white};
            border: thin solid #c5c3db; // var(--map-outline-color)
            color: ${props.theme.colors.purple800};
            box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06);
  
            &:hover:not(:disabled):not(.active) {
              background: ${props.theme.colors.purple50};
            }
  
            > svg {
              fill: ${props.theme.colors.purple800};
            }

            &.active {
              background: ${props.theme.colors.purple800};
              color: ${props.theme.colors.white};

              > svg {
                fill: ${props.theme.colors.white};
              }
            }
          `;
      default:
        return `
          background: ${props.theme.colors.purple50};
          color: ${props.theme.colors.purple800};
          border: 1px solid ${props.theme.colors.grey100};

          &:hover:not(:disabled):not(.active) {
            background: ${props.theme.colors.purple50};
            color: ${props.theme.colors.purple800};
            border-color: ${props.theme.colors.purple800};
          }

          > svg {
            fill: ${props.theme.colors.purple800};
          }

          &.active {
            background: ${props.theme.colors.purple50};
            border: 1px solid ${props.theme.colors.purple800};
            color: ${props.theme.colors.purple800};
          }
        `;
    }
  }}
`;

const StyledLink = styled(Link)<{
  'data-margin-left': boolean;
  'data-margin-right': boolean;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
}>`
  display: inline-block;

  &.active ${StyledButton} {
    cursor: default;
    border-color: initial;

    > svg {
      fill: currentColor;
    }

    ${props => {
      switch (props.variant) {
        case 'primary':
          return `
            background: ${props.theme.colors.purple800};
            border: 1px solid ${props.theme.colors.purple800};
            color: ${props.theme.colors.white};
              `;
        case 'primary-outline':
          return `
            background: ${props.theme.colors.white};
            border: 1px solid ${props.theme.colors.purple800};
            color: ${props.theme.colors.purple800};
          `;
        case 'danger':
          return `
            background: ${props.theme.colors.red900};
            border-color: ${props.theme.colors.red900};
            color: ${props.theme.colors.white};
          `;
        case 'danger-outline':
          return `
            background: ${props.theme.colors.red500};
            border-color: ${props.theme.colors.red500};
            color: ${props.theme.colors.white};
          `;
        default:
          return `
            background: ${props.theme.colors.purple50};
            border: 1px solid ${props.theme.colors.purple800};
            color: ${props.theme.colors.purple800};
          `;
      }
    }}
  }

  ${props => {
    switch (props.size) {
      case 'small':
        return `
          margin-left: ${props['data-margin-left'] ? 8 : 0}px;
          margin-right: ${props['data-margin-right'] ? 8 : 0}px;
        `;
      case 'large':
        return `
          margin-left: ${props['data-margin-left'] ? 12 : 0}px;
          margin-right: ${props['data-margin-right'] ? 12 : 0}px;
        `;
      default:
        return `
          margin-left: ${props['data-margin-left'] ? 10 : 0}px;
          margin-right: ${props['data-margin-right'] ? 10 : 0}px;
        `;
    }
  }}
`;

export default React.forwardRef(Button);
