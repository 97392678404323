import styled from 'styled-components';
import React, { useState, useCallback } from 'react';
import { Modal as BootstrapModal, ModalProps as BootstrapModalProps } from 'react-bootstrap';
import Button, { ButtonProps } from './Button';

export interface ModalProps extends BootstrapModalProps {
  size?: 'sm' | 'lg' | 'xl';
  title?: string | React.ReactElement;
  titleKey?: string;
  backdrop?: true | false | 'static';
  children?: React.ReactElement;
  customButton?: React.ReactElement;
  cancelButtonProps?: ButtonProps;
  submitButtonProps?: ButtonProps;
  collapsible?: boolean;
  getIntl?: (localeKey: string, options?: {}) => string;
}

const Modal: React.FC<ModalProps> = ({
  size = 'lg',
  title,
  titleKey,
  backdrop = 'static',
  children,
  customButton,
  cancelButtonProps,
  submitButtonProps,
  collapsible = false,
  getIntl = str => str,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleCollapsibleButtonClick = useCallback(() => {
    setCollapsed(collapsed => !collapsed);
  }, []);

  return (
    <StyledBootstrapModal
      backdrop={backdrop}
      size={size}
      centered
      {...props}
      data-collapsible={collapsible}
      data-collapsed={collapsed}
    >
      {(title || titleKey) && (
        <StyledBootstrapModalHeader closeButton data-collapsible={collapsible}>
          <BootstrapModal.Title>{title || (titleKey && getIntl(titleKey))}</BootstrapModal.Title>
          {collapsible && (
            <button type="button" className="close" onClick={handleCollapsibleButtonClick}>
              <span aria-hidden="true">{collapsed ? '+' : '-'}</span>
            </button>
          )}
        </StyledBootstrapModalHeader>
      )}
      {children && <BootstrapModal.Body>{children}</BootstrapModal.Body>}
      {(customButton || cancelButtonProps || submitButtonProps) && (
        <BootstrapModal.Footer>
          {customButton}
          {cancelButtonProps && (
            <Button
              dataMarker="popup_modal_close"
              variant="primary-outline"
              {...cancelButtonProps}
              labelKey={cancelButtonProps.labelKey || 'Cancel'}
              getIntl={getIntl}
            />
          )}
          {submitButtonProps && <Button marginLeft variant="primary" {...submitButtonProps} getIntl={getIntl} />}
        </BootstrapModal.Footer>
      )}
    </StyledBootstrapModal>
  );
};

const StyledBootstrapModal = styled(BootstrapModal)<{
  'data-collapsible': boolean;
  'data-collapsed': boolean;
}>`
  ${props =>
    props['data-collapsible'] &&
    `
      .modal-header {
        ${props['data-collapsed'] && `border-bottom: 0;`}
      }

      .modal-body {
        transition: ${
          props['data-collapsed']
            ? `max-height 0.15s ease-out;`
            : `max-height 0.15s ease-in, opacity 0.15s ease-in 0.15s;`
        };
        max-height: ${props['data-collapsed'] ? 0 : '1000px'};
        opacity: ${props['data-collapsed'] ? 0 : 1};
      }

      .modal-footer {
        transition: ${
          props['data-collapsed']
            ? `max-height 0.15s ease-out;`
            : `max-height 0.15s ease-in, opacity 0.15s ease-in 0.15s;`
        };
        max-height: ${props['data-collapsed'] ? 0 : `100px`};
        opacity: ${props['data-collapsed'] ? 0 : 1};
      }

      ${
        props['data-collapsed'] &&
        `
          .modal-body,
          .modal-footer {
            overflow: hidden;
            padding: 0;
          }
        `
      };
    `};
`;

const StyledBootstrapModalHeader = styled(BootstrapModal.Header)<{ 'data-collapsible': boolean }>`
  align-items: center;

  ${props =>
    props['data-collapsible'] &&
    `
      .close:last-child {
        margin-left: 1rem;
      }
    `};
`;

export default Modal;
