import { useState, useEffect, useCallback } from 'react';

interface Props {
  fetchInterval: number;
}

export default function useAutoFetch({ fetchInterval }: Props) {
  const [autoFetchTimeStamp, setAutoFetchTimeStamp] = useState(Date.now());
  const [nextUpdateDiff, setNextUpdateDiff] = useState('');
  const [shouldFetch, setShouldFetch] = useState(false);

  const resetCounter = useCallback(() => {
    setAutoFetchTimeStamp(Date.now());
    setNextUpdateDiff(String(fetchInterval / 1000));
    setShouldFetch(false);
  }, [fetchInterval]);

  useEffect(() => {
    const updateInterval = 1000; // 1 second in milliseconds

    const intervalId = setInterval(() => {
      const now = Date.now();
      const timeSinceLastFetch = now - autoFetchTimeStamp;
      if (timeSinceLastFetch > fetchInterval) return setShouldFetch(true);
      const nextDiff = Math.ceil((fetchInterval - timeSinceLastFetch) / 1000);
      const formattedNextDiff = String(nextDiff).padStart(2, '0');
      setNextUpdateDiff(formattedNextDiff);
    }, updateInterval);

    return () => clearInterval(intervalId);
  }, [autoFetchTimeStamp, fetchInterval]);

  return {
    nextUpdateDiff,
    shouldFetch,
    resetCounter,
  };
}
