import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers } from 'hooks';
import { DataTableTemplate, Checkbox } from '@utiligize/shared/components';
import { PaginationType } from 'constants/index';
import TableTests from './TableTests';
import { SelectTenant } from 'components/_common';
import SelectTags from './SelectTags';

const ViewFunctionalityTests: React.FC = () => {
  const type = PaginationType.ADMIN_FUNCTIONALITY_TESTS;
  const { filters, handleSelectChange, handleCheckboxClick } = usePaginationHandlers({ type });

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectTenant isClearable name="tenant" value={filters?.tenant || null} onChange={handleSelectChange} />
        </Col>
        <Col xs={3}>
          <SelectTags name="tags" value={filters?.tags || null} onChange={handleSelectChange} />
        </Col>
        <Col xs="auto">
          <Checkbox
            labelKey="Success"
            className="icheck-primary"
            name="success"
            checked={filters?.success}
            onChange={handleCheckboxClick}
          />
        </Col>
      </Row>
      <TableTests />
    </DataTableTemplate>
  );
};

export default ViewFunctionalityTests;
