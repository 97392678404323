import styled from 'styled-components';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { testOverviewsSelector } from 'modules/uptime/selectors';
import { fetchTestOverviewsAction } from 'modules/uptime';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

interface Props {
  shouldFetch: boolean;
  resetCounter: () => void;
}

const TableTestOverview: React.FC<Props> = ({ shouldFetch, resetCounter }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_TEST_OVERVIEW;
  const items = useSelector(testOverviewsSelector);

  const sendRequest = useCallback(
    () => dispatch(fetchTestOverviewsAction()).finally(resetCounter),
    [dispatch, resetCounter]
  );

  useEffect(() => {
    if (shouldFetch) sendRequest();
  }, [shouldFetch, resetCounter, sendRequest]);

  const renderCell = (value: number) => (
    <td
      className={classNames('text-center', {
        'bg-success': value === 100,
        'bg-warning': value >= 80 && value < 100,
        'bg-danger': value < 80,
      })}
    >
      {typeof value === 'number' ? `${value}%` : '-'}
    </td>
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={items?.length}
      sendRequest={sendRequest}
      containerClassName="border-top-0"
    >
      {items.map(item => (
        <StyledTR key={item.tenant}>
          <td>{item.tenant || '-'}</td>
          {renderCell(item.map)}
          {renderCell(item.data_quality)}
          {renderCell(item.network_loading)}
          {renderCell(item.investment)}
          {renderCell(item.customer)}
          {renderCell(item.n_1)}
          {renderCell(item.co2e)}
          {renderCell(item.setup)}
          {renderCell(item.new_load)}
          {renderCell(item.download_reports)}
          {renderCell(item.maintenance_and_planning)}
          {renderCell(item.reinvestment)}
        </StyledTR>
      ))}
    </DataTable>
  );
};

const StyledTR = styled.tr`
  td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
`;

export default TableTestOverview;
