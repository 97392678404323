import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.uptime;

export const testsCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testsCount'] => uptime.testsCount
);

const testsHashSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testsHash'] => uptime.testsHash
);

export const testsSelector = createSelector(testsHashSelector, (testsHash: Uptime.Root['testsHash']): Uptime.Test[] =>
  Object.values(testsHash)
);

export const testOverviewsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testOverviews'] => uptime.testOverviews
);

export const testOverviewsRunConfettiSelector = createSelector(
  testOverviewsSelector,
  (testOverviews: Uptime.Root['testOverviews']): boolean =>
    Boolean(testOverviews?.length) &&
    testOverviews.every(item =>
      Object.values(item)
        .filter(i => typeof i === 'number')
        .every(key => key === 100)
    )
);

export const testOverviewsLastTestRunTimestampSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testOverviewsLastTestRunTimestamp'] => uptime.testOverviewsLastTestRunTimestamp
);

export const historyCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['historyCount'] => uptime.historyCount
);

export const historyItemsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['historyItems'] => uptime.historyItems
);

export const statusOverviewsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['statusOverviews'] => uptime.statusOverviews
);
