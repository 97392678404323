import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { AssetLifeAPI, PaginationType } from 'constants/index';
import { setSuccessToastAction } from 'modules/layouts';

// ------------------------------------
// Actions
// ------------------------------------
export const getVersionChartData = createAction(
  'calculations/GET_VERSION_CHART_DATA',
  (tenant: string) => (): Promise<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x' | 'categories'>[] | null> => {
    return AssetLifeAPI.get('monitoring/get_version_data/', { params: { tenant } }).then(res => res.data);
  }
);

export const fetchFerretsAction: any = createAction(
  'calculations/FETCH_FERRETS',
  async () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Calculations.Root, 'ferrets'>> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.ADMIN_FERRETS)(state);
      return AssetLifeAPI.get(`gcp_workflows/list_ferret_executions`, {
        params: { tenant: filters?.tenant, env: filters?.environment },
      }).then(res => ({ ferrets: res.data }));
    }
);

export const startFerretAction: any = createAction(
  'calculations/START_FERRET',
  async (env: Layouts.Filters['environment'], tenant: Layouts.Filters['tenant']) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      return AssetLifeAPI.post(`gcp_workflows/start_ferret_execution`, { tenant, env }).then(async () => {
        await dispatch(fetchFerretsAction());
        dispatch(setSuccessToastAction('Ferret execution successfully started'));
      });
    }
);

export const cancelFerretAction = createAction(
  'calculations/CANCEL_FERRET',
  (name: string) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.ADMIN_FERRETS)(state);
      return AssetLifeAPI.post('gcp_workflows/cancel_ferret_execution', {
        execution_name: name,
        env: filters?.environment,
      }).then(async () => {
        await dispatch(fetchFerretsAction());
        dispatch(setSuccessToastAction('Ferret execution successfully cancelled'));
      });
    }
);

export const fetchLoadflowAction: any = createAction(
  'calculations/FETCH_LOADFLOW',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Calculations.Root, 'loadflowItems'>> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.ADMIN_LOADFLOW)(state);
      return AssetLifeAPI.get(`gcp_workflows/list_executions`, {
        params: { tenant: filters?.tenant, env: filters?.environment, flow: filters?.flow },
      }).then((res: any) => ({ loadflowItems: res.data }));
    }
);

export const cancelLoadflowAction = createAction(
  'calculations/CANCEL_LOADFLOW',
  (executionNameFull: string) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.ADMIN_LOADFLOW)(state);
      return AssetLifeAPI.post('gcp_workflows/cancel_execution', {
        execution_name_full: executionNameFull,
        env: filters?.environment,
      }).then(async () => {
        await dispatch(fetchLoadflowAction());
        dispatch(setSuccessToastAction('Loadflow execution successfully cancelled'));
      });
    }
);

export const startLoadflowAction = createAction(
  'calculations/START_LOADFLOW',
  (values: Calculations.NewExecution) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      return AssetLifeAPI.post('gcp_workflows/start_execution', {
        flow: values.flow,
        env: values.environment,
        tenant: values.tenant,
        steps: values.steps?.join(', '),
        misc: values.misc,
      }).then(() => {
        dispatch(setSuccessToastAction('Loadflow execution successfully started'));
      });
    }
);
