import { _pick, _omit } from '@utiligize/shared/utils';
import { StorageTypes } from 'constants/index';

const STOR_KEY = 'UTILIGIZE_ADMIN_DATA';

/**
 * Get a set of props from localStorage
 */
export const getStorageItem = <T>(
  name: string | string[],
  storageType: Type.StorageTypes = StorageTypes.LOCAL_STORAGE
): T | null => {
  if (!window[storageType]) return null;
  const localConfigStr: string | null = window[storageType].getItem(STOR_KEY);
  if (!localConfigStr) return null;
  const localConfig = JSON.parse(localConfigStr);
  if (Array.isArray(name)) return _pick(localConfig, name) as T;
  return localConfig[name] ?? null;
};

/**
 * Save a set of props to localStorage
 */
export const setStorageItem = (modifier: object, storageType: Type.StorageTypes = StorageTypes.LOCAL_STORAGE): void => {
  if (!window[storageType]) return;
  const localConfigStr: string | null = window[storageType].getItem(STOR_KEY);
  const localConfig = localConfigStr ? JSON.parse(localConfigStr) : {};
  const nextLocalConfig = { ...localConfig, ...modifier };
  window[storageType].setItem(STOR_KEY, JSON.stringify(nextLocalConfig));
};

/**
 * Remove a set of props from localStorage
 */
export const removeStorageItem = (
  name: string | string[],
  storageType: Type.StorageTypes = StorageTypes.LOCAL_STORAGE
): void => {
  if (!window[storageType]) return;
  const localConfigStr: string | null = window[storageType].getItem(STOR_KEY);
  if (!localConfigStr) return;
  const localConfig = JSON.parse(localConfigStr);
  const names: string[] = Array.isArray(name) ? name : [name];
  const nextConfig = _omit(localConfig, names);
  if (Object.keys(nextConfig).length) {
    const nextLocalConfigStr: string = JSON.stringify(nextConfig);
    window[storageType].setItem(STOR_KEY, nextLocalConfigStr);
  } else {
    window[storageType].removeItem(STOR_KEY);
  }
};

/**
 * Clear localStorage
 */
export const clearStorage = (storageType: Type.StorageTypes = StorageTypes.LOCAL_STORAGE): void => {
  if (!window[storageType]) return;
  window[storageType].removeItem(STOR_KEY);
};
