import axios from 'axios';
import { generatePath } from 'react-router';
import Routes from './routes';
export * from './storage';

export const AssetLifeAPI = axios.create({
  baseURL: `${process.env.REACT_APP_ASSETLIFE_API_URL}/`,
});

export const DefaultRoute = generatePath(Routes.FunctionalityTests);

// Each table pagination and controls meta stored to redux separately
export enum PaginationType {
  ADMIN_STATUS_OVERVIEW = 'ADMIN_STATUS_OVERVIEW',
  ADMIN_TEST_OVERVIEW = 'ADMIN_TEST_OVERVIEW',
  ADMIN_FUNCTIONALITY_TESTS = 'ADMIN_FUNCTIONALITY_TESTS',
  ADMIN_HISTORY = 'ADMIN_HISTORY',
  ADMIN_FERRETS = 'ADMIN_FERRETS',
  ADMIN_LOADFLOW = 'ADMIN_LOADFLOW',
}

export const TableHeaders: { [key in PaginationType]: Type.DataTableHeader[] } = {
  [PaginationType.ADMIN_STATUS_OVERVIEW]: [
    { title: 'Tenant' },
    { title: 'Latest GIS data' },
    { title: 'Latest successful version' },
    { title: 'Latest successful simulation' },
    { title: 'Latest successful map update' },
    { title: 'Map data is less than one day old', className: 'text-center' },
    { title: 'Dashboard tests', className: 'text-center' },
  ],
  [PaginationType.ADMIN_TEST_OVERVIEW]: [
    { title: 'Tenant' },
    { title: 'Map', className: 'text-center' },
    { title: 'Data quality', className: 'text-center' },
    { title: 'Network loading', className: 'text-center' },
    { title: 'Investment', className: 'text-center' },
    { title: 'Customer', className: 'text-center' },
    { title: 'N-1', className: 'text-center' },
    { title: 'CO₂e', className: 'text-center' },
    { title: 'Setup', className: 'text-center' },
    { title: 'New load', className: 'text-center' },
    { title: 'Download', className: 'text-center' },
    { title: 'Maintenance & Planning', className: 'text-center' },
    { title: 'Reinvestment', className: 'text-center' },
  ],
  [PaginationType.ADMIN_FUNCTIONALITY_TESTS]: [
    { title: 'Name', sortKey: 'name' },
    { title: 'Tenant', sortKey: 'tenant' },
    { title: 'Portfolio', sortKey: 'portfolio' },
    { title: 'Scenario', sortKey: 'scenario' },
    { title: 'Timestamp', sortKey: 'test_run_timestamp' },
    { title: 'Duration', sortKey: 'duration' },
    { title: 'Tags', sortKey: 'tags' },
    { title: 'Success', sortKey: 'success' },
    { title: 'Message', sortKey: 'error_message' },
    { title: 'Dashboard version', sortKey: 'dashboard_version' },
    { title: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ADMIN_HISTORY]: [
    { title: 'Timestamp', sortKey: 'test_run_timestamp' },
    { title: 'Duration' },
    { title: 'Tags' },
    { title: 'Success' },
    { title: 'Message' },
    { title: 'Dashboard version' },
  ],
  [PaginationType.ADMIN_FERRETS]: [
    { title: 'Tenant' },
    { title: 'Type' },
    {
      title: 'Time',
      className: 'text-center',
      subTitles: [{ title: 'Start' }, { title: 'End' }, { title: 'Total' }],
    },
    { title: 'State', className: 'text-center' },
    { title: 'Logs', className: 'text-center' },
    { title: 'Error message', className: 'text-center' },
    { title: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ADMIN_LOADFLOW]: [
    { title: 'Tenant' },
    { title: 'Steps' },
    { title: 'Misc' },
    {
      title: 'Time',
      className: 'text-center',
      subTitles: [{ title: 'Start' }, { title: 'End' }, { title: 'Total' }],
    },

    { title: 'State', className: 'text-center' },
    { title: 'Logs', className: 'text-center' },
    { title: 'Error message', className: 'text-center' },
    { title: 'Actions', className: 'text-right' },
  ],
};

export { MaxMobileWidth, isDevelopment } from '@utiligize/shared/constants';

export { Routes };
