import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import layoutsReducer from 'modules/layouts';
import uptimeReducer from 'modules/uptime';
import optionsReducer from 'modules/options';
import calculationsReducer from 'modules/calculations';

const createRootReducer = (history: Type.History) =>
  combineReducers({
    router: connectRouter(history),
    layouts: layoutsReducer,
    uptime: uptimeReducer,
    options: optionsReducer,
    calculations: calculationsReducer,
  });

export default createRootReducer;
