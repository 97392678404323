export enum StorageTypes {
  LOCAL_STORAGE = 'localStorage',
  SESSION_STORAGE = 'sessionStorage',
}

export enum StorageKeys {
  LAST_ROUTE = 'LAST_ROUTE',
  USER_CLAIMS = 'USER_CLAIMS',
  PAGINATION = 'PAGINATION',
  PAGINATION_CACHE_VERSION = 'PAGINATION_CACHE_VERSION',
}
