import { handleActions, combineActions } from 'redux-actions';
import {
  fetchTenantOptionsAction,
  fetchWorkflowTenantOptionsAction,
  fetchTagOptionsAction,
  fetchTestOptionsAction,
  fetchPortfolioOptionsAction,
  fetchScenarioOptionsAction,
  fetchLoadflowFlowOptionsAction,
  fetchLoadflowEnvironmentOptionsAction,
  fetchLoadflowStepsOptionsAction,
  fetchLoadflowFlowTypeOptionsAction,
  fetchLoadflowPortfolioOptionsAction,
  fetchLoadflowScenarioOptionsAction,
  fetchLoadflowInvestmentScenarioOptionsAction,
} from './actions';

const initialState: Options.Root = {
  tenantsOptions: null,
  workflowTenantsOptionsHash: {},
  tagsOptions: null,
  testsOptions: null,
  portfolioOptionsHash: {},
  scenarioOptionsHash: {},
  loadflowFlowOptionsHash: {},
  loadflowEnvironmentOptions: null,
  loadflowStepsOptionsHash: {},
  loadflowFlowTypeOptionsHash: {},
  loadflowPortfolioOptionsHash: {},
  loadflowScenarioOptionsHash: {},
  loadflowInvestmentScenarioOptionsHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchTenantOptionsAction,
    fetchWorkflowTenantOptionsAction,
    fetchTagOptionsAction,
    fetchTestOptionsAction,
    fetchPortfolioOptionsAction,
    fetchScenarioOptionsAction,
    fetchLoadflowFlowOptionsAction,
    fetchLoadflowEnvironmentOptionsAction,
    fetchLoadflowStepsOptionsAction,
    fetchLoadflowFlowTypeOptionsAction,
    fetchLoadflowPortfolioOptionsAction,
    fetchLoadflowScenarioOptionsAction,
    fetchLoadflowInvestmentScenarioOptionsAction
  ) as any]: {
    next: (state: Options.Root, action: Shared.ReduxAction<Partial<Options.Root>>): Options.Root => ({
      ...state,
      ...action.payload,
    }),
  },
};

export {
  fetchTenantOptionsAction,
  fetchWorkflowTenantOptionsAction,
  fetchTagOptionsAction,
  fetchTestOptionsAction,
  fetchPortfolioOptionsAction,
  fetchScenarioOptionsAction,
  fetchLoadflowFlowOptionsAction,
  fetchLoadflowEnvironmentOptionsAction,
  fetchLoadflowStepsOptionsAction,
  fetchLoadflowFlowTypeOptionsAction,
  fetchLoadflowPortfolioOptionsAction,
  fetchLoadflowScenarioOptionsAction,
  fetchLoadflowInvestmentScenarioOptionsAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
