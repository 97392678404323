import { createAction } from 'redux-actions';
import queryString from 'query-string';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const resetReduxStateAction: any = createAction(
  'uptime/RESET_REDUX_STATE',
  (nextState: Partial<Uptime.Root>) => nextState
);

export const fetchTestOverviewsAction: any = createAction(
  'uptime/FETCH_TEST_OVERVIEWS',
  async () => (): Promise<Pick<Uptime.Root, 'testOverviews' | 'testOverviewsLastTestRunTimestamp'>> => {
    return AssetLifeAPI.get('monitoring/dashboard_health_overview').then(res => ({
      testOverviews: res.data.rows,
      testOverviewsLastTestRunTimestamp: res.data.meta?.timestamp_last_test_run,
    }));
  }
);

export const fetchFunctionalityTestsAction: any = createAction(
  'uptime/FETCH_FUNCTIONALITY_TESTS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Uptime.Root, 'testsCount' | 'testsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.ADMIN_FUNCTIONALITY_TESTS
      )(state);
      return AssetLifeAPI.get('monitoring/selenium_tests', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          tenant: filters?.tenant,
          tags: filters?.tags,
          success: filters?.success,
        },
        paramsSerializer: params => queryString.stringify(params),
      }).then((res: any) => ({
        testsCount: res.data.count,
        testsHash: _keyBy(res.data.rows, (item: Uptime.Test) => item.test_run_timestamp),
        skipStoreUpdate,
      }));
    }
);

export const fetchHistoryAction: any = createAction(
  'uptime/FETCH_HISTORY',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Uptime.Root, 'historyCount' | 'historyItems'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.ADMIN_HISTORY)(
        state
      );
      return AssetLifeAPI.get('monitoring/dashboard_test_history', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          test: filters?.test,
          tenant: filters?.tenant,
          portfolio: filters?.portfolio,
          scenario: filters?.scenario,
        },
        paramsSerializer: params => queryString.stringify(params),
      }).then((res: any) => ({
        historyCount: res.data.count,
        historyItems: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const fetchStatusOverviewsAction: any = createAction(
  'uptime/FETCH_STATUS_OVERVIEWS',
  async () => (): Promise<Pick<Uptime.Root, 'statusOverviews'>> => {
    return AssetLifeAPI.get('monitoring/last_version_simulation').then(res => ({ statusOverviews: res.data.rows }));
  }
);
