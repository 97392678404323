import React from 'react';
import { useSelector } from 'react-redux';
import { Toasts as SharedToasts } from '@utiligize/shared/components';
import { toastsSelector } from 'modules/layouts/selectors';
import { deleteToastAction } from 'modules/layouts';

const Toasts: React.FC = () => {
  const toasts = useSelector(toastsSelector);
  return <SharedToasts toasts={toasts} deleteToastAction={deleteToastAction} />;
};

export default Toasts;
