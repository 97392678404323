import React from 'react';
import FormInput from './FormInput';

const HiddenFormSubmit: React.FC<{ autoFocus?: boolean }> = ({ autoFocus = false }) => (
  <div style={{ border: '0 none', height: 0, width: 0, padding: 0, margin: 0, overflow: 'hidden' }}>
    {autoFocus && <FormInput autoFocus name="hidden-input-to-focus-on-form" />}
    <input type="submit" tabIndex={-1} />
  </div>
);

export default HiddenFormSubmit;
