import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ferretsSelector } from 'modules/calculations/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { cancelFerretAction, fetchFerretsAction } from 'modules/calculations';
import { Button } from '@utiligize/shared/components';
import { DataTable } from 'components/_common';
import { dateFormat } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';
import { IconSlash } from '@utiligize/shared/resources';

interface Props {
  shouldFetch: boolean;
  resetCounter: () => void;
}

const TableFerrets: React.FC<Props> = ({ shouldFetch, resetCounter }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_FERRETS;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const items = useSelector(ferretsSelector);

  const sendRequest = useCallback(() => dispatch(fetchFerretsAction()).finally(resetCounter), [dispatch, resetCounter]);

  useEffect(() => {
    if (shouldFetch) sendRequest();
  }, [shouldFetch, sendRequest]);

  const handleCancelButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const executionNameFull: string | null = event.currentTarget.getAttribute('data-name');
      if (!executionNameFull) return;
      dispatch(cancelFerretAction(executionNameFull));
    },
    [dispatch]
  );

  const renderStateCell = (value: string) => (
    <td
      className={classNames('text-center', {
        'bg-success': value === 'SUCCEEDED',
        'bg-warning': value === 'CANCELLED',
        'bg-danger': value === 'FAILED',
      })}
    >
      {value}
    </td>
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={Number(items?.length)}
      sendRequest={sendRequest}
      waitForDependencies={!filters?.environment}
      maxHeight="calc(100vh - 170px)"
    >
      {(items || []).map(item => (
        <tr key={item.name}>
          <td className="text-nowrap">{item.tenant}</td>
          <td className="text-nowrap">{item.type || '-'}</td>
          <td className="text-nowrap">{item.start_time ? dateFormat(item.start_time, 'YYYY-MM-DD, hh:mm:ss') : '-'}</td>
          <td className="text-nowrap">
            {item.end_time === 'Still Running'
              ? item.end_time
              : item.end_time
                ? dateFormat(item.end_time, 'YYYY-MM-DD, hh:mm:ss')
                : '-'}
          </td>
          <td className="text-nowrap">{item.duration || '-'}</td>
          {renderStateCell(item.state)}
          <td className="text-nowrap text-center">
            <a href={item.all_logs} target="_blank" rel="noopener noreferrer">
              all
            </a>
            {' | '}
            <a href={item.error_logs} target="_blank" rel="noopener noreferrer">
              errors
            </a>
          </td>
          <td>{item.error_msg || '-'}</td>
          <td className="text-right">
            {item.state === 'ACTIVE' ? (
              <Button
                tooltipKey="Cancel"
                icon={<IconSlash />}
                data-name={item.name}
                onClick={handleCancelButtonClick}
                size="small"
                variant="primary"
              />
            ) : (
              '-'
            )}
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableFerrets;
