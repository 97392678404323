import Highcharts from 'highcharts';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { useWindowSize } from 'hooks';
import { isSidebarOpenSelector } from 'modules/layouts/selectors';
import { PageNotFound } from 'pages';
import { Navigation, Sidebar } from 'components';
import { ViewStatusOverview } from 'components/StatusOverview';
import { ViewTestOverview } from 'components/TestOverview';
import { ViewFunctionalityTests } from 'components/FunctionalityTests';
import { ViewHistory } from 'components/History';
import { ViewFerrets } from 'components/Ferrets';
import { ViewLoadflow } from 'components/Loadflow';
import { ViewVersionDetails } from 'components/VersionDetails';
import { setStorageItem } from 'utils';
import { Routes, StorageKeys } from 'constants/index';

const PageMain: React.FC<RouteComponentProps> = ({ location }) => {
  const isMobile = useWindowSize();
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  useEffect(() => {
    // Automatic high charts reflow trigger
    setTimeout(() => Highcharts.charts.forEach(c => c?.reflow()), 500);
  }, [isSidebarOpen]);

  useEffect(() => {
    // Update last route on routing
    setStorageItem({ [StorageKeys.LAST_ROUTE]: `${location.pathname}${location.hash}` });
  }, [location.pathname, location.hash]);

  const renderContent = () => (
    <Switch>
      <Route exact path={Routes.StatusOverview} component={ViewStatusOverview} />
      <Route exact path={Routes.TestOverview} component={ViewTestOverview} />
      <Route exact path={Routes.FunctionalityTests} component={ViewFunctionalityTests} />
      <Route exact path={Routes.History} component={ViewHistory} />
      <Route path={Routes.Ferrets} component={ViewFerrets} />
      <Route path={Routes.Loadflow} component={ViewLoadflow} />
      <Route path={Routes.VersionDetails} component={ViewVersionDetails} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );

  return (
    <div
      className={classNames('sidebar-mini', 'layout-fixed', {
        'sidebar-collapse': !isSidebarOpen,
        'sidebar-open': isSidebarOpen && isMobile,
      })}
    >
      <div className="wrapper overflow-hidden">
        <Navigation />
        <Sidebar isMobile={isMobile} />
        <StyledContentWrapper className="content-wrapper position-relative" $isSidebarOpen={isSidebarOpen}>
          {renderContent()}
        </StyledContentWrapper>
      </div>
    </div>
  );
};

const StyledContentWrapper = styled.div<{ $isSidebarOpen: boolean }>`
  &&&& {
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.colors.grey25};
    min-height: calc(100vh - ${props => props.theme.heights.topNavigation});
    margin-top: ${props => props.theme.heights.topNavigation};

    ${({ $isSidebarOpen }) => !$isSidebarOpen && `margin-left: 60px!important`};
  }
`;

export default PageMain;
